import React from 'react';
import './InstitutionDetails.css'; // Import CSS file for styling

const InstitutionDetails = ({logo,description}) => {
  return (
    <div className="institution-container">
      <div className="logo-container">
        <img src={process.env.PUBLIC_URL +logo} alt="Institution Logo" className="logo" />
      </div>
      <div className="details-container">
        <p className="institution-description">{description}</p>
        
      </div>
    </div>
  );
};

export default InstitutionDetails;
