import React from "react";

const Courses = ({ courses }) => {
    

  if (!courses || courses.length === 0) {
    return <p>No courses.</p>;
  }

  return (
    <div>
    {courses.map((course) => (
      <div key={course.id}>
        <p>University:{course.uni}</p>
        <p>Undergraduate Courses:{course.ug}</p>
        <p>Postgraduate Courses:{course.pg}</p>
        {course.phd && <p>Phd: {course.phd}</p>}
        
      </div>
    ))}
  </div>
  );
};

export default Courses;
