import React, { lazy, Suspense } from "react";

// Use React.lazy to lazily load the components
const Hero = lazy(() => import("./hero/Hero"));
const ImageRow = lazy(() => import("./imageRow/imageRow"));
const BlogList = lazy(() => import("./blogList/blogList"));
const Special = lazy(() => import("./special/Special"));
const Events = lazy(() => import("./events/Events"));
const Contact = lazy(() => import("../contact/Contact"));

const Home = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Hero />
        <ImageRow />
        <BlogList />
        <Special />
        <Events />
        {/* <Content /> */}
        <Contact />
        {/* <Featured />
        <Recent />
        <Awards />
        <Location />
        <Team />
        <Price /> */}
      </Suspense>
    </>
  );
};

export default Home;
