import React from "react"
// import { footer } from "../../data/Data"
import "./Footer.css"

const Footer = () => {
  return (
    <>
    
      <section className='footerContact'>
        <div className='footer-container'>
          <div className='send flex'>
            <div className='text'>
              <p>Do You Have Questions ?</p>
            </div>
            
            <a href="/contact"><button className='btn5'>Contact Us Today</button></a>
          </div>
        </div>
      </section>

      {/* <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
               <img src='../images/logo-light.png' alt='' /> 
              <h2>Study in Bahrain</h2>
              <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>

              <div className='input flex'>
                <input type='text' placeholder='Email Address' />
                <button>Subscribe</button>
              </div> 
            </div>
          </div>

          {footer.map((val) => (
            <div className='box'>
              <h3>{val.title}</h3>
              <ul>
                {val.text.map((items) => (
                  <li> {items.list} </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer> */}
      <div className='legal'>
        <span>McIndeez Consultancy</span>
      </div>
    </>
  )
}

export default Footer