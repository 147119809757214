import React from "react"
import Heading from "../common/Heading"
import "./About.css"
import Top from "../common/top/Top"

const About = () => {
  return (
    <>
      <section className='about'>
      <Top title="About Us"/>
        {/* <Back name='About Us' title='About Us - Who We Are?' cover="/images/1.jpg" /> */}
        <div className='about-container flex mtop'>
          <div className='left row'>
            <Heading title='Study In Bahrain' />

{/* subtitle='Check out our company story and work process' */}

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
            {/* <button className='btn2'>More About Us</button> */}
          </div>
          <div className='right row'>
            <img src={process.env.PUBLIC_URL +'/images/2.jpg'} alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About