import React from "react"
import { useParams } from 'react-router-dom';
import './UniversityDetails.css';
import { universities } from "../../data/Data"
import Top from "../../common/top/Top";
import InstitutionDetails from "../../common/institution/InstitutionDetails";
import Slider from "../../common/slider/Slider";
import ContactInfo from "../../contactinfo/ContactInfo";
import Courses from "../../common/courses/Courses";

function UniversityDetails() {
    const { id } = useParams(); 
    const university = universities.find((university) => university.id === id); // find the university with the given id
  
    if (!university) {
        return <div>Sorry, we could not find a university with the ID "{id}"</div>;
    }
    // else{
    //     setTitle(university.title);
    // }

    return (
      <div className="det">
          <Top title={university.title}/>
          <InstitutionDetails logo={university.logo} description={university.description}/>
        <h1>Curriculum</h1>
        <p>{university.curriculum}</p>
        <h1>Courses</h1>
        {/* <p>{university.courses}</p>  */}
        <Courses courses={university.courses} />         
        <Slider directorMessages={university.directorMessages} />
        <h1>Tution Fees</h1>
        <p>{university.fees}</p>
        {/* <h1>Gallery</h1> */}
        <h1>Contact</h1>
        <ContactInfo contact={university.contact}
        email={university.email}
        address={university.address}
        location={university.location}
        facebook={university.facebook}
        instagram={university.instagram}
        twitter={university.twitter} />
      </div>
    );
  }

export default UniversityDetails;