import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';
import './ContactInfo.css';

const ContactInfo = ({contact,email,address,location,facebook,instagram,twitter}) => {
  return (
    <div className="row">
      <div className="contact-info">
        <ul>
          <li>Contact : {contact}</li>
          <li>Email : {email}</li>
          <li>Address : {address}</li>
        </ul>
      </div>
      <div className="social-media">
        <ul>
          <a href={location}><li><FaMapMarkerAlt /></li></a>
          <a href={facebook}><li><FaFacebook /></li></a>
          <a href={twitter}><li><FaTwitter /></li></a>
          <a href={instagram}><li><FaInstagram /></li></a>
        </ul>
      </div>
    </div>
  );
};

export default ContactInfo;
