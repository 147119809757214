import React, { useState } from "react";
import Card from "../home/card/Card";
import { universities } from "../data/Data";
import { Link } from 'react-router-dom';
import Top from "../common/top/Top";
import "./Universities.css";

const Universities = () => {
  const [universityList] = useState("tab1");

  return (
    <>
      <Top title="Universities in Bahrain" />
      <section className='content21'>
        <div className='container21'>
          <div className="row">
            <div className="col-md-8">
              <div className="card-container">
                {universities.map((list, index) => (
                  <div className="card-wrapper" key={index}>
                    <Link to={`/university/${list.id}`}>
                      <Card
                        title={list.title}
                        subheading={list.place}
                        description={list.description}
                        logo={list.logo}
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4">
              <div className="vertical-image-container">
                <img src={process.env.PUBLIC_URL + '/images/ad3.webp'} alt="Vertical Image" className="vertical-image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Universities;
