import React from "react"
import { useParams } from 'react-router-dom';
import './SchoolDetails.css';
import { schools } from "../../data/Data"
import Top from "../../common/top/Top";
import InstitutionDetails from "../../common/institution/InstitutionDetails";
import Slider from "../../common/slider/Slider";
import ContactInfo from "../../contactinfo/ContactInfo";

function SchoolDetails() {
    const { id } = useParams(); 
    const school = schools.find((school) => school.id === id); // find the school with the given id
  
    if (!school) {
        return <div>Sorry, we could not find a school with the ID "{id}"</div>;
    }
    // else{
    //     setTitle(school.title);
    // }

    return (
      <div class="det">
          <Top title={school.title}/>
          <InstitutionDetails logo={school.logo} description={school.description}/>
        <h1>Curriculum</h1>
        <p>{school.curriculum}</p>
        <Slider directorMessages={school.directorMessages} />
        <h1>Tution Fees</h1>
        <p>{school.fees}</p>
        {/* <h1>Gallery</h1> */}
        <h1>Contact</h1>
        <ContactInfo contact={school.contact}
        email={school.email}
        address={school.address}
        location={school.location}
        facebook={school.facebook}
        instagram={school.instagram}
        twitter={school.twitter} />
      </div>
    );
  }

export default SchoolDetails;