import React from "react"
import Heading from "../Heading"
import "./Top.css"

const Top = ({title}) => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <Heading title={title}  />
        </div>
      </section>
    </>
  )
}

export default Top