import React, { useState } from "react";
import Card from "../home/card/Card";
import { schools } from "../data/Data";
import { Link } from 'react-router-dom';
import Top from "../common/top/Top";
import "./Schools.css";

const Schools = () => {
  const [schoolList] = useState("tab1");

  return (
    <>
      <Top title="Schools in Bahrain" />
      <section className='content21'>
        <div className='container21'>
          <div className="row">
            <div className="col-md-8">
              <div className="card-container">
                {schools.map((list, index) => (
                  <div className="card-wrapper col-lg-6 col-md-6 col-sm-12" key={index}>
                    <Link to={`/school/${list.id}`}>
                      <Card
                        title={list.title}
                        subheading={list.place}
                        description={list.description}
                        logo={list.logo}
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4">
              <div className="vertical-image-container">
                <img src={process.env.PUBLIC_URL + '/images/ad3.webp'} alt="Vertical Image" className="vertical-image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Schools;






