import React from 'react';
import './Card.css';

const Card = ({ id, title, subheading, description, logo }) => {
  return (
    <div className="card">
      <div className="logo">
        <img src={process.env.PUBLIC_URL +logo} alt="Logo" className="logo-image" />
      </div>
      <div className="content">
        <div className="title-row">
          <h3 className="card-title">{title}</h3>
        </div>
        {/* <div className="subheading-row">
          <h3 className="card-subheading">{subheading}</h3>
        </div> */}
        {/* <p className="card-description">{description}</p> */}
      </div>
    </div>
  );
};

export default Card;

