import React from "react"
import Header from "../common/header/Header"
import Footer from "../common/footer/Footer"
import Home from "../home/Home"
import SchoolDetails from "../schools/schooldetails/SchoolDetails"
import UniversityDetails from "../universities/universitydetails/UniversityDetails"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Schools from "../schools/Schools"
import Universities from "../universities/Universities"
import Contact from "../contact/Contact"
import SearchResults from "../common/search/SearchResults"
import About from "../about/About"

const Pages = () => {
  return (
    <>
    <Router>
      <Header />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/school/:id' element={<SchoolDetails />} />
        <Route exact path='/university/:id' element={<UniversityDetails />} />
        <Route exact path='/schools' element={<Schools />} />
        <Route exact path='/universities' element={<Universities />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path="/search-results" element={<SearchResults />} />
         <Route exact path='/about' element={<About />} />
        {/*<Route exact path='/services' component={Services} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/pricing' component={Pricing} />
        <Route exact path='/contact' component={Contact} /> */}
      </Routes>
      <Footer />
    </Router>
  </>
  )
}

export default Pages