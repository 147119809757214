import React from "react"
import "./Contact.css"

const Contact = () => {
  return (
    <>
      <section className='contact mb'>
        <div className='container'>
          <form className='shadow' action="https://formsubmit.co/it@mcindeez.com" method="POST">
            <h4>Fill up The Form</h4> <br />
            <div>
              <input name="name" type='text' placeholder='Name' required />
              <input name="email" type='email' placeholder='Email' required/>
            </div>
            <input name="subject" type='text' placeholder='Subject' required/>
            <textarea name="message" cols='30' rows='10' required></textarea>
            <button>Submit Request</button>
          </form>
        </div>
      </section>
    </>
  )
}

export default Contact