import React, { useState } from "react";
import "./Slider.css";

const Slider = ({ directorMessages }) => {
    const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    setActiveIndex(activeIndex === 0 ? directorMessages.length - 1 : activeIndex - 1);
  };

  const handleNext = () => {
    setActiveIndex(activeIndex === directorMessages.length - 1 ? 0 : activeIndex + 1);
  };

  if (!directorMessages || directorMessages.length === 0) {
    return <p>No director messages to display.</p>;
  }

  return (
    <div className="slider-container">
      <div className="slider-nav">
        <button className="slider-prev" onClick={handlePrev}>
          &lt;
        </button>
        <button className="slider-next" onClick={handleNext}>
          &gt;
        </button>
      </div>
      <div className="slider-content">
        <div className="slider-message">
          {/* <div className="slider-image">
            <img src={directorMessages[activeIndex].photo} alt={directorMessages[activeIndex].name} />
          </div> */}
          <div className="slider-text">
            <h2>{directorMessages[activeIndex].name}</h2>
            <p>{directorMessages[activeIndex].designation}</p>
            <p>{directorMessages[activeIndex].message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
