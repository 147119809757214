import React from "react"
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { images } from "../data/Data";
// import "./Heading.css";


const Heading = ({ title, subtitle }) => {
  return (
    <>
      <div className='heading'>
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
    </>
  )
}

// const Heading = ({ title, subtitle }) => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   return (
//     <Slider {...settings}>
//       {images.map((image, index) => (
//         <div key={index} className="heading-slide">
//           <img src={image} alt={`Slide ${index + 1}`} />
//           <div className="heading-content">
//             <h1>{title}</h1>
//             <p>{subtitle}</p>
//           </div>
//         </div>
//       ))}
//     </Slider>
//   );
// };


export default Heading