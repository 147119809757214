import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { schools } from "../../data/Data";
import { universities } from "../../data/Data";
import { Link } from "react-router-dom";
import Card from "../../home/card/Card";

const SearchResults = () => {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const searchQuery = new URLSearchParams(location.search).get("query");

  useEffect(() => {
    // Combine schools and universities into a single array
    const allItems = [...schools, ...universities];

    // Filter the data based on the search query
    const filteredResults = allItems.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResults(filteredResults);
  }, [searchQuery]);

  return (
    <div>
      <h2 style={{ padding: '20px' }}>Search Results Page</h2>
      {searchResults.length > 0 ? (
        <ul className="search-results">
          <div
            className={searchResults ? "small" : "flex"}
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {searchResults.map((item) => (
              <Link
                to={item.type === "university" ? `/university/${item.id}` : `/school/${item.id}`}
                key={item.id}
              >
                <Card
                  title={item.title}
                  subheading={item.place}
                  description={item.description}
                  logo={item.logo}
                  style={{ width: "30%" }}
                />
              </Link>
            ))}
          </div>
          {/* {searchResults.map((item) => (
            <li key={item.id}>{item.title}</li>
          ))} */}
        </ul>
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
};

export default SearchResults;
